/* Alert.css */
.alert {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
    transition: all 0.3s ease;
    max-width: 80%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
}

.alert-error {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.alert > svg {
    margin-right: 10px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}

